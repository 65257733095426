import il8n from '@/assets/language/index.js';
const { t } = il8n.global;
const noZero1 = (rule, value,callback)=>{
    if(value < 0 ){
        callback(new Error(t('currencySet.rule1')))
    }else{
        callback()
    }
}
const mainRules = {
    tenantId: [{ required: true, message: t('inputTit.xuanze'), trigger: 'blur' }],
    appId: [{ required: true, message: t('inputTit.xuanze'), trigger: 'blur' }],
    collectionId_: [{ required: true, message: t('inputTit.xuanze'), trigger: 'blur' }],
    itemUrl: [{ required: true, message: t('inputTit.shangchuan'), trigger: 'blur' }],
    itemName: [{ required: true, message: t('inputTit.shuru'), trigger: 'blur' }],
    description: [{ required: true, message: t('inputTit.shuru'), trigger: 'blur' }],
    nftCreatorAddr: [{ required: true, message: t('inputTit.shuru'), trigger: 'blur' }],
    qty: [{ required: true, message: t('inputTit.shuru'), trigger: 'blur' },{ validator: noZero1, trigger: 'blur' }],
    googleCode: [{ required: true, message: t('inputTit.shuru'), trigger: 'blur' }],
}
export default{
    mainRules
}
<template>
    <div class="init">
        <el-form :model="fromValue" label-width="auto" ref='form_' class="fromClass"
            :rules='rules' label-position='top'>
            <el-form-item :label="$t('currencySetNft.name1')" prop="tenantId">
                <el-select v-model="fromValue.tenantId" :placeholder="$t('inputTit.xuanze')" @change="getItem('tenantId')"  :teleported='false'>
                    <el-option :label="item.name" :value="item.id" v-for='item in tenantArray' :key = 'item.id'/>
                </el-select>
            </el-form-item>
            <el-form-item :label="$t('currencySetNft.name2')" prop="appId">
                <el-select v-model="fromValue.appId" :placeholder="$t('inputTit.xuanze')" @change="getItem('appId')"  :teleported='false'>
                    <el-option :label="item.appName" :value="item.appId" v-for='item in appArray' :key = 'item.appId'/>
                </el-select>
            </el-form-item>
            <el-form-item :label="$t('nfts.text13')" prop="collectionId">
                <!-- <el-select v-model="fromValue.collectionName" :placeholder="$t('inputTit.xuanze')" @change="getCoin_" :disabled='fromValue.appId?false:true'  :teleported='false'>
                    <el-option :value="item" v-for='item in seriesArray' :key = 'item.collectionId'> 
                        <span style="float: left;color:#666">{{item.collectionName}}</span>
                    </el-option>
                </el-select> -->
                <el-input v-model="fromValue.collectionName" disabled  :placeholder="$t('inputTit.xuanze')">
                    <template #append v-if='fromValue.appId'>
                        <el-button text type="primary" @click="chooseAddr(2)">{{$t('button.checks')}}</el-button>
                    </template>
                </el-input>
            </el-form-item>
             <el-form-item :label="$t('nfts.name10')" prop="itemUrl">
                <el-upload :auto-upload='false' :limit="1" :on-exceed='handleExceed' ref="upload"
                    :on-change="beforeAvatarUpload">
                    <template #trigger>
                        <el-button type="primary" size="small" >{{$t('button.upload')}}</el-button>
                    </template>
                    <template #tip>
                        <div class="lineWrite">
                            {{$t('nfts.text18')}}  {{$t('nfts.text19')}}<br />
                        </div>
                    </template>
                </el-upload>
                <!-- <el-input v-model="fromValue.collectionPcImg" :placeholder="$t('inputTit.shuru')"/> -->
            </el-form-item>
             <el-form-item :label="$t('nfts.name11')" prop="itemName">
                <el-input v-model="fromValue.itemName" :placeholder="$t('inputTit.shuru')"/>
            </el-form-item>
             <el-form-item :label="$t('nfts.name12')" prop="description">
                <el-input v-model="fromValue.description" :placeholder="$t('inputTit.shuru')" type='textarea'/>
            </el-form-item>
             <el-form-item :label="$t('nfts.name5')" prop="nftCreatorAddr">
                <el-input v-model="fromValue.nftCreatorAddr" :placeholder="$t('inputTit.shuru')" v-if='fromValue.mintType === 1' disabled/>
                <el-input v-model="fromValue.nftCreatorAddr" disabled  :placeholder="$t('inputTit.xuanze')" v-else>
                    <template #append v-if='fromValue.collectionName'>
                        <el-button text type="primary" @click="chooseAddr(1)">{{$t('button.checks')}}</el-button>
                    </template>
                </el-input>
            </el-form-item>
             <el-form-item :label="$t('nfts.name19')" prop="qty">
                <el-input v-model="fromValue.qty" :placeholder="$t('inputTit.shuru')" type="number" :min="0" :disabled='fromValue.protocol === "ERC721"'/>
            </el-form-item>
             <el-form-item :label="$t('news.name5')" prop="itemProperty">
                <property-view ref='propertyView_'/>
            </el-form-item>
             <el-form-item :label="$t('currencySetNft.name29')" prop="googleCode">
                <el-input v-model="fromValue.googleCode" :placeholder="$t('inputTit.shuru')"/>
            </el-form-item>
            <!-- <el-row :gutter="24" justify="end">
                <el-col :span="10" >
                    <el-button @click="emit('successFun')">{{$t('button.closeQ')}}</el-button>
                    <el-button type="primary" @click="completeFun(form_)">{{$t('button.enter')}}</el-button>
                </el-col>
            </el-row> -->
        </el-form>
        <el-dialog v-model="innerVisible1" width="750" :title="arrayText[style_]" append-to-body >
            <addrView @getCoin='getCoin1' ref='addrView_' v-if='style_ === 1'/>
            <chainView @getCoin='getCoin1' ref='chainView_' v-else/>
        </el-dialog>
    </div>
</template>
<script setup>
    import { ref,reactive,getCurrentInstance,nextTick,defineExpose } from 'vue'
    import { userStore } from '@/store/user'
    import Api_ from '@/api/index'
    import { ElNotification } from 'element-plus'
    import addRules from './addRules'
    import apiList from '@/const/apiList'
    // import { Plus } from '@element-plus/icons-vue'
    const { $t } = getCurrentInstance().proxy;
    import { randomCoding } from '@/utils/util.js'
    import { genFileId } from 'element-plus'
    import propertyView from './property.vue'
    import addrView from './addr.vue'
    import chainView from './chain.vue'
    
    const fromValue = reactive({ 
        userId: '',tenantId:'',appId:'',collectionId:'',collectionName:'',
        itemUrl:'',itemName:'',description:'',
        nftCreatorAddr:'',fileSuffix:'',googleCode:'',qty:1,
        nftOwnerType: 2,ownnerId:'',chain:'',protocol:'',mintType:'',
        storageType: 1
    })
    const fileValue = ref([])
    const innerVisible1 = ref(false);const addrView_ = ref(null)

    //初始化是平台还是商家
    const isPlatform = ref(null);isPlatform.value = userStore().getIsPlatform
    fromValue.tenantId = userStore().getUserInfo[0].tenantId
    
    //初始化商家和app数组
    const arrayText = ['',$t('nfts.name5'),$t('nfts.text13')]
    const tenantArray = ref([])
    const appArray = ref([])
    // const seriesArray = ref([])
    // const creatorArray = ref([])
    apiList.getTenantList((data)=>{ tenantArray.value = data })
    apiList.getAppList((data)=>{appArray.value = data;},fromValue.tenantId)
    //链，协议；地址参数
    // fromValue
    const form_ = ref(null)
    const rules = ref(null);rules.value = addRules.mainRules
    const upload = ref(null)
    const propertyView_ = ref(null)
    const style_ = ref(null);
    const chainView_ = ref(null)
    
    const chooseAddr = (num)=>{//打开选择地址
        style_.value = num
        innerVisible1.value = true
        nextTick(()=>{
            if(num === 1){
                addrView_.value.init_({
                    tenantId:fromValue.tenantId,
                    walletType: 4,
                    chain: fromValue.chain,
                    protocol: fromValue.protocol,
                    appId:fromValue.appId,
                })
            }else{
                chainView_.value.init_({
                    tenantId:fromValue.tenantId,
                    appId:fromValue.appId,
                })

            }
        })
    }
    const getCoin1 = (e)=>{
        innerVisible1.value = false
        if(style_.value === 1){
            fromValue.nftCreatorAddr = e.walletAddress
        }else{
            fromValue.chain = e.chain
            fromValue.protocol = e.protocol
            fromValue.collectionName = e.collectionName
            fromValue.collectionId = e.collectionId
            fromValue.mintType = e.mintType
            fromValue.storageType = e.storageType
            fromValue.nftCreatorAddr = e.mintType === 1? e.creator:''
            if(fromValue.protocol === 'ERC721'){
                fromValue.qty = 1
            }
        }
    }
    const handleExceed = (file)=>{
        let file_ = file[0]
        upload.value.clearFiles()
        file_.uid = genFileId()
        upload.value.handleStart(file_)
    }
    const completeFun = (blak)=>{//点击完成
        if (!form_.value) return
        form_.value.validate((e) => {
            if(e){
                fromValue.userId = fromValue.tenantId
                fromValue.ownnerId = fromValue.tenantId
                let obj_ = JSON.parse(JSON.stringify(fromValue))
                obj_.transactionId = randomCoding(64)
                delete obj_.googleCode
                let _as = { file: fileValue.value,itemInfo: JSON.stringify(obj_),itemProperty: JSON.stringify(propertyView_.value.getTableData()) }
                Api_.addNftiteminfo(_as,fromValue.googleCode).then(res=>{
                    if(res.code === 0){
                        blak()
                        form_.value.resetFields()
                        ElNotification({
                            title: $t('alert.succAdd'),
                            type:'success',
                            message: $t('news.name4'),
                            duration: 0
                        })
                    }else{
                        ElNotification({
                            message: res.msg,
                            type: 'error',
                        })
                    }
                })
            }
        })
    }
    const beforeAvatarUpload = (e)=>{//无感知上传后缀名
        let file = new FormData()
        file.append("file",e.raw)
        fileValue.value = e.raw
        fromValue.itemUrl = 1
    }
    const getItem = (str)=>{//选项框的联动设置
        if(str === 'tenantId'){
            fromValue.appId = ''
            apiList.getAppList((data)=>{appArray.value = data;},fromValue.tenantId)
            // apiList.getNftSeries((data)=>{seriesArray.value = data;},{
            //     tenantId:fromValue.tenantId,
            // })
        }
        fromValue.collectionId = ''
        fromValue.collectionName = ''
    }
    defineExpose({completeFun})
</script>
<style lang="scss" scoped>
    :deep(.avue-form__group .el-col) {
        display: flex;
    }
    .el-icon.avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        text-align: center;
    }
    :deep(.avatar-uploader .el-upload) {
        border: 1px dashed #ccc;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        transition: var(--el-transition-duration-fast);
    }
    .lineWrite{
        line-height: 18px;
        font-size: 10px;
        margin-left: 16px;
        display: flex;
        align-items: flex-end;
        color: red
    }
</style>